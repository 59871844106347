import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";

function App() {
	const [theme, setTheme] = useState("dark");

	useEffect(() => {
		document.documentElement.classList.toggle("dark", theme === "dark");
	}, [theme]);

	const toggleTheme = () => {
		setTheme(theme === "dark" ? "light" : "dark");
	};

	useEffect(() => {
		const handleSmoothScroll = (e) => {
			e.preventDefault();
			const href = e.currentTarget.getAttribute("href");
			const targetId = href.replace("#", "");
			const elem = document.getElementById(targetId);
			elem?.scrollIntoView({
				behavior: "smooth"
			});
		};

		const links = document.querySelectorAll('a[href^="#"]');
		links.forEach((link) => {
			link.addEventListener("click", handleSmoothScroll);
		});

		return () => {
			links.forEach((link) => {
				link.removeEventListener("click", handleSmoothScroll);
			});
		};
	}, []);

	return (
		<div className="font-sans bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 min-h-screen">
			<Header theme={theme} toggleTheme={toggleTheme} />
			<main>
				<About />
				<Skills />
				<Projects />
				<Contact />
			</main>
		</div>
	);
}

export default App;
