import React, { useState } from "react";

function Header({ theme, toggleTheme }) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<header className="bg-gray-800 dark:bg-gray-950 text-white py-4 sticky top-0 z-50">
			<div className="container mx-auto px-4">
				<div className="flex justify-between items-center">
					<h1 className="text-2xl md:text-3xl font-bold">mrbeandev</h1>
					<div className="flex items-center">
						<button onClick={toggleTheme} className="p-2 rounded-full bg-gray-700 dark:bg-gray-800 hover:bg-gray-600 dark:hover:bg-gray-700 transition duration-300 mr-4">
							{theme === "dark" ? "🌞" : "🌙"}
						</button>
						<button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden focus:outline-none">
							<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</button>
					</div>
				</div>
				<nav className={`${isMenuOpen ? "block" : "hidden"} md:block mt-4 md:mt-0`}>
					<ul className="md:flex md:space-x-6">
						<li className="mb-2 md:mb-0">
							<a href="#about" className="block hover:text-blue-400 transition duration-300">
								About
							</a>
						</li>
						<li className="mb-2 md:mb-0">
							<a href="#skills" className="block hover:text-blue-400 transition duration-300">
								Skills
							</a>
						</li>
						<li className="mb-2 md:mb-0">
							<a href="#projects" className="block hover:text-blue-400 transition duration-300">
								Projects
							</a>
						</li>
						<li className="mb-2 md:mb-0">
							<a href="#contact" className="block hover:text-blue-400 transition duration-300">
								Contact
							</a>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
}

export default Header;
