import React from "react";

const skills = [
	{ name: "PHP", level: 80 },
	{ name: "Python", level: 80 },
	{ name: "JavaScript", level: 70 },
	{ name: "HTML", level: 90 },
	{ name: "CSS", level: 80 },
	{ name: "MySQL", level: 80 },
	{ name: "MongoDB", level: 60 },
	{ name: "Prisma", level: 80 }
];

function SkillBar({ name, level }) {
	return (
		<div className="mb-4 md:mb-6">
			<div className="flex justify-between mb-1">
				<span className="text-sm md:text-base font-medium text-blue-500 dark:text-blue-400">{name}</span>
				<span className="text-xs md:text-sm font-medium text-blue-500 dark:text-blue-400">{level}%</span>
			</div>
			<div className="w-full bg-gray-300 dark:bg-gray-700 rounded-full h-2 md:h-2.5">
				<div className="bg-blue-600 h-2 md:h-2.5 rounded-full transition-all duration-500 ease-out" style={{ width: `${level}%` }}></div>
			</div>
		</div>
	);
}

function Skills() {
	return (
		<section id="skills" className="py-16 md:py-20 bg-gray-100 dark:bg-gray-900">
			<div className="container mx-auto px-4">
				<h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-center">My Skills</h2>
				<div className="max-w-3xl mx-auto">
					{skills.map((skill, index) => (
						<SkillBar key={index} name={skill.name} level={skill.level} />
					))}
				</div>
			</div>
		</section>
	);
}

export default Skills;
