import React from "react";

function About() {
	return (
		<section id="about" className="py-16 md:py-20 bg-gray-200 dark:bg-gray-800">
			<div className="container mx-auto px-4">
				<h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-center">About Me</h2>

				<div className="max-w-4xl mx-auto flex flex-col items-center">
					<div className="mb-8 md:mb-12">
						<div className="w-48 h-48 md:w-56 md:h-56 relative">
							<img src="/images/logo.png" alt="mrbeandev" className="rounded-full w-full h-full object-cover shadow-lg" />
						</div>
					</div>

					<div className="text-center md:text-left">
						<p className="text-base md:text-lg mb-4">Hi, I'm mrbeandev, a Tech Innovator with over 5 years of experience. I specialize in crafting robust, scalable solutions across various technologies.</p>
						<p className="text-base md:text-lg mb-4">My expertise includes AI applications, Website Development (Frontend + Backend), and Telegram WebApps. I'm proficient in PHP, Python, JavaScript (ReactJs, NextJs, VueJs), HTML, CSS, MySQL, MongoDB, and Prisma.</p>
						<p className="text-base md:text-lg">I have experience managing Ubuntu, Debian, and CentOS operating systems. My passion lies in solving complex problems and delivering high-performance applications.</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
