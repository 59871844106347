import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ProjectSlider({ images }) {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		arrows: false,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		customPaging: function (i) {
			return (
				<a>
					<img src={images[i]} alt={`Thumbnail ${i + 1}`} className="w-full h-full object-cover rounded" />
				</a>
			);
		},
		dotsClass: "slick-dots slick-thumb flex"
	};

	return (
		<div className="project-slider">
			<Slider {...settings}>
				{images.map((image, index) => (
					<div key={index} className="aspect-w-16 aspect-h-9">
						<img src={image} alt={`Project image ${index + 1}`} className="w-full h-full object-cover object-top rounded-t-lg" />
					</div>
				))}
			</Slider>
		</div>
	);
}

function ProjectCard({ project }) {
	const [isExpanded, setIsExpanded] = useState(false);
	const maxLength = 100; // Adjust this value to set the maximum number of characters to show initially

	const toggleReadMore = (e) => {
		e.preventDefault();
		setIsExpanded(!isExpanded);
	};

	const truncatedDescription = project.description.slice(0, maxLength);
	const shouldTruncate = project.description.length > maxLength;

	return (
		<div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg flex flex-col h-full">
			<div className="flex-shrink-0">
				<ProjectSlider images={project.images} />
			</div>
			<div className="p-4 md:p-6 flex flex-col flex-grow">
				<h3 className="text-xl md:text-2xl font-bold mb-3 mt-8">{project.title}</h3>
				<p className="text-sm md:text-base flex-grow">
					{isExpanded ? project.description : truncatedDescription}
					{shouldTruncate && !isExpanded && "..."}
					{shouldTruncate && (
						<button onClick={toggleReadMore} className="text-blue-500 hover:text-blue-600 ml-1 font-medium">
							{isExpanded ? "Read less" : "Read more"}
						</button>
					)}
				</p>
				<div className="mt-4">
					<strong className="text-sm md:text-base">Technologies:</strong>{" "}
					<div className="flex flex-wrap mt-2">
						{project.technologies.map((tech, index) => (
							<span key={index} className="inline-block bg-blue-500 text-white px-2 py-1 rounded-full text-xs md:text-sm mr-2 mb-2">
								{tech}
							</span>
						))}
					</div>
				</div>
				{project.link && (
					<div className="mt-4">
						<a href={project.link} target="_blank" rel="noopener noreferrer" className="inline-block bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300">
							View Project
						</a>
					</div>
				)}
			</div>
		</div>
	);
}

function Projects() {
	const [projects, setProjects] = useState([]);
	const [displayedProjects, setDisplayedProjects] = useState(3);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		fetch("/projects.json")
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch projects");
				}
				return response.json();
			})
			.then((data) => {
				setProjects(data);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching projects:", error);
				setError(error.message);
				setIsLoading(false);
			});
	}, []);

	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};

	const handleViewAll = () => {
		setDisplayedProjects(projects.length);
	};

	if (isLoading) {
		return <div className="text-center py-16">Loading projects...</div>;
	}

	if (error) {
		return <div className="text-center py-16 text-red-500">Error: {error}</div>;
	}

	return (
		<section id="projects" className="py-16 md:py-20 bg-gray-200 dark:bg-gray-800">
			<div className="container mx-auto px-4">
				<h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-center">My Projects</h2>

				{/* Mobile view: Slider */}
				<div className="md:hidden">
					<Slider {...settings}>
						{projects.map((project) => (
							<div key={project.id} className="px-2">
								<ProjectCard project={project} />
							</div>
						))}
					</Slider>
				</div>

				{/* Desktop view: Grid */}
				<div className="hidden md:grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
					{projects.slice(0, displayedProjects).map((project) => (
						<ProjectCard key={project.id} project={project} />
					))}
				</div>

				{/* View All Projects button */}
				{displayedProjects < projects.length && (
					<div className="text-center mt-8">
						<button onClick={handleViewAll} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300">
							View All Projects
						</button>
					</div>
				)}
			</div>
		</section>
	);
}

export default Projects;
